/* eslint-disable react/display-name */
import React, { memo, useState, useEffect, useContext } from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ListCheck from "../../zzz/atoms/icons/files/list-check.jsx"
import StarRatings from "react-star-ratings"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { addToCompare } from "../Products/CompareProducts"
import CartContext from "../../context/CartContext"
import { shippingClassGet, formatPrice, slugify } from "../../lib/util.jsx"
import { sizeBeautify, singleStringSize } from "../../lib/stringHandling"
import { isInStoreFrontend } from "../../services/auth"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ProductCard = ({
  gatsbyImage,
  name,
  rating = {},
  price = 0,
  salePrice,
  salePriceRangeMin,
  salePriceRangeMax,
  stockData,
  slug,
  sku,
  selectedSize,
  id,
  src,
  parentId,
  showCompare = false,
  className = "",
  searchAnalyticsFunction = () => {},
}) => {
  const {
    file: {
      childImageSharp: { gatsbyImageData: fallback },
    },
  } = useStaticQuery(PLACEHOLDER)

  const stockCount = stockData.stockCount
  const shippingClass = stockData.shippingClass
  const { setCompareDrawerOpen } = useContext(CartContext)

  const title = `${name}${
    !selectedSize ? "" : ` - ${sizeBeautify(selectedSize)}`
  }`

  const formattedPrice = formatPrice(price)
  const formattedSalePrice = formatPrice(salePrice)

  const formattedSavings = savings(price, salePrice)
  const formattedPercentage = percentage(price, salePrice)

  const formattedSalePriceRangeMin = !formattedSalePrice
    ? formatPrice(salePriceRangeMin)
    : false
  const formattedSalePriceRangeMax = !formattedSalePrice
    ? formatPrice(salePriceRangeMax)
    : false
  const isInStoreFrontendState = isInStoreFrontend()

  let linkUrl = `/product/${slug}/`
  if (selectedSize) {
    const linkSlug = singleStringSize(selectedSize)
    if (linkSlug) {
      // linkUrl = `${linkUrl}?size=${linkSlug}`
      linkUrl = `${linkUrl}?option=${linkSlug}`
    }
  }

  // Check which kind of image to render
  const ImageToRender = () => {
    if (gatsbyImage) {
      return <GatsbyImage image={gatsbyImage} alt={title} />
    } else if (src) {
      return <img src={src} alt={title} />
    } else {
      return <GatsbyImage image={fallback} alt={title} />
    }
  }

  // Parse rating data so that nothing breaks
  const ratingAverage = parseFloat(rating.average)
  const ratingCount = parseInt(rating.count)

  // Get all the shipping info for this product according to shipping class
  const shippingInfo = shippingClassGet(shippingClass)

  return (
    <Card
      className={`product-card ${className}`}
      isInStoreFrontendState={isInStoreFrontendState}
    >
      <StyledLink to={linkUrl} onClick={() => searchAnalyticsFunction(id)}>
        <Label
          color={shippingInfo.textColor}
          backgroundColor={shippingInfo.backgroundColor}
        >
          {shippingInfo.text}
        </Label>
        <ImageToRender />
      </StyledLink>
      <div>
        <Link to={linkUrl} onClick={() => searchAnalyticsFunction(id)}>
          <h5>{title}</h5>
        </Link>
      </div>
      {isInStoreFrontendState && (
        <div>
          <a style={{ fontWeight: "300", fontSize: "15px" }}>{sku}</a>
        </div>
      )}
      <Icons>
        {ratingAverage > 0 ? (
          <div>
            <StarRatings
              rating={ratingAverage}
              numberOfStars={5}
              name="rating"
              starDimension="15px"
              starSpacing="2px"
              starRatedColor="#F7A416"
              starEmptyColor="#CFD5DB"
              starHoverColor="#ffba42"
            />
          </div>
        ) : (
          <div />
        )}
        {showCompare && (
          <SvgIcon
            SvgComponent={ListCheck}
            className="list-check"
            size="md"
            shade="100"
            hue="tusk"
            onClick={() => {
              addToCompare(parentId ? parentId : id)
              setCompareDrawerOpen(true)
            }}
          />
        )}
      </Icons>
      <Dash />
      <Half>
        {formattedSalePrice ? (
          <Amounts>
            <p
              className={
                formattedPrice == formattedSalePrice ? "price r_price" : "price"
              }
            >
              {formattedSalePrice}
            </p>
            {formattedPrice == formattedSalePrice && (
              <PriceSnippet>Our warehouse price to you!</PriceSnippet>
            )}
            {formattedPrice && formattedPrice != formattedSalePrice && (
              <>
                <DiscountLabel color={"teal"}>
                  {formattedPercentage}% OFF!
                </DiscountLabel>
                <Savings>
                  <p className="old-price">{formattedPrice}</p>
                  You save {formattedSavings}
                </Savings>
              </>
            )}
          </Amounts>
        ) : formattedSalePriceRangeMin && formattedSalePriceRangeMax ? (
          <Amounts className="amounts-range">
            <p className="price price-range">{`${formattedSalePriceRangeMin} - ${formattedSalePriceRangeMax}`}</p>
          </Amounts>
        ) : null}
      </Half>
      <Btn
        to={linkUrl}
        state={{ selectedSize }}
        onClick={() => searchAnalyticsFunction(id)}
      >
        View Product
      </Btn>
    </Card>
  )
}

const savings = (price, salePrice) => {
  return formatPrice(parseFloat(price) - parseFloat(salePrice))
}

const percentage = (price, salePrice) => {
  const saving = parseFloat(price) - parseFloat(salePrice)
  return parseInt((saving / parseFloat(price)) * 100)
}
// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const StyledLink = styled(Link)`
  max-width: 320px;
  text-align: center;
`

const Label = styled.div`
  position: absolute;
  top: 20px;
  z-index: 1;
  right: 20px;
  text-transform: uppercase;
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 0px 10px;
  font-size: 13px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PriceSnippet = styled.div`
  color: #0988bb;
  font-size: 16px;
`

const DiscountLabel = styled.div`
  position: absolute;
  right: 10px;
  color: white;
  padding: 0px 10px;
  background: #0988bb;
  font-size: 13px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dash = styled.div`
  border: 1px dashed #e4e4e4;
`

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .star-ratings {
    align-self: flex-start;
  }
  p {
    color: red;
    font-size: 13px;
    line-height: 1;
  }
  .list-check {
    transition: 0.2s all ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-3px);
    }
  }
  .heart {
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    animation: none;
    &:hover {
      animation: ${pulse} 1.5s infinite;
    }
    &:active {
      transform: scale(1);
    }
  }
`
const Half = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Amounts = styled.div`
  &.amounts-range {
    width: 100%;
  }
  p.price {
    color: #223247;
    font-size: 20px;
    font-weight: bold;
    &.price-range {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    &.r_price {
      padding-bottom: 27px;
    }
  }
  p.old-price {
    color: #9ba5ae;
    font-size: 16px;
    text-decoration: line-through;
  }
`

const Savings = styled.span`
  color: ${({ color }) => (color ? color : "#0988bb")};
  font-size: 14px;
`

const Btn = styled(Link)`
  text-align: center;
  width: 100%;
  color: white;
  text-transform: uppercase;
  padding: 7px;
  border-radius: 3px;
  background-color: #223247;
  border: none;
  font-size: 16px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
  }
`

const Card = styled.div`
  min-width: 250px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  border-radius: 4px;
  overflow: hidden;
  padding: 20px;
  display: grid;
  grid-template-rows: 250px 85px ${({ isInStoreFrontendState }) =>
      isInStoreFrontendState ? "5px" : ""} 20px 1px 90px;
  grid-gap: 10px;
  align-items: center;
  position: relative;
  img {
    height: 200px;
    border-radius: 3px;
    overflow: hidden;
  }
  h5 {
    color: #223247;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 20px auto;
    max-width: 320px;
    width: 100%;
  }
`

const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "placeholders/placeholderBed.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export default memo(ProductCard)
